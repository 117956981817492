
import SchoolsContractsFieldsService from "@/modules/schools-contracts-fields/schools-contracts-fields-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            SchoolsContractsFieldsService.create(payload).then(response => {
                resolve({
                    message: "SchoolsContractsFields criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar SchoolsContractsFields",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            SchoolsContractsFieldsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "SchoolsContractsFields atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar SchoolsContractsFields",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsFieldsService.remove(id).then(response => {
                resolve({
                    message: "SchoolsContractsFields removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover SchoolsContractsFields",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsFieldsService.delete(id).then(response => {
                resolve({
                    message: "SchoolsContractsFields deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar SchoolsContractsFields",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsFieldsService.get(id).then(response => {
                resolve({
                    message: "SchoolsContractsFields encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContractsFields",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            SchoolsContractsFieldsService.paginate(query).then(response => {
                resolve({
                    message: "SchoolsContractsFields encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContractsFields",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            SchoolsContractsFieldsService.all().then(response => {
                resolve({
                    message: "SchoolsContractsFields encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContractsFields",
                    success: false,
                    error
                });
            })
        })
    }
}
